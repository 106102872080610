import React from 'react';
import { MySchedule } from './MySchedule';
import { TimeZone } from 'components/common/TimeZone';
const MeetingSchedulePage: React.FC = () => {

    React.useEffect(() => {
        document.title = `My Meeting Schedule | ${globalThis.showTitle}`;
    }, []);

    return (
        <div className="page-content">
            <h1>
                My Meetings
            </h1>
            <div className="content-html"  >
                <div className="right-aligned">
                    <TimeZone />
                </div>
                <MySchedule showRemoveButton={false} />
            </div>
        </div>
    );
};
export default MeetingSchedulePage;
