import React from 'react';
import {
    ColumnSettings,
    CatalogOrder,
    OrderMode,
    OrderColumnType,
    MediaProps,
    MediaType
} from 'model/index';

interface Props {
    column: ColumnSettings,
    css: string,
    item: any
}
import { Badge, Space  } from 'antd';
//import clsx from 'clsx';
//import styles from './ItemRow.module.css';

import { FileTwoTone, FilePdfTwoTone, VideoCameraTwoTone, CheckOutlined } from '@ant-design/icons';

import { formatMoney } from 'utility/utility';

import { useAppState } from 'components/layout/AppStateProvider';

export const Column: React.FC<Props> = (props) => {

    const cutoff: number = 10;

    const value = props.item[props.column.data];

    const item = props.item;

    const { showMedia } = useAppState();


    const onClick = () => {
        console.log(value);
        window.open(value);
    };

    const onImageClick = () => {
        //console.log(item);

        let media: MediaProps = {
            mediaType: MediaType.Image,
            url: item.AdURL,
            title: `${item.VendorName} ${item.ItemNo}: ${item.Description}`,
            vendorAccountId: item.VendorAccountId
        };

        showMedia(media);

    };

    const getValue = () => {
        let result: JSX.Element = <React.Fragment />;

        switch (props.column.columnType) {
            case OrderColumnType.File:
                if (value && value.length > 1) {
                    result = <div className="icon" onClick={onClick} >
                        <FileTwoTone style={{ fontSize: 24, paddingRight: 10, paddingLeft: 5 }} />
                    </div>;
                }
                break;
            case OrderColumnType.Money:
                result = <span>{formatMoney(value)}</span>;
                break;
            case OrderColumnType.Text:
                result = <span>{value}</span>;
                break;

            case OrderColumnType.CheckBox:
                if (value) {
                    result = <CheckOutlined />;
                }
                break;
            case OrderColumnType.Number:
                if (value != null && value > 0) {
                    return <span>{value}</span>;
                }
                break;
            case OrderColumnType.Decimal:
                if (value != null && value > 0) {
                    return <span>{Number.parseFloat(value).toFixed(2)}</span>;
                }
                break;
            case OrderColumnType.Date:
                if (value) {
                    result = <span>{value}</span>;
                }
                break;
            default:
                result = <span>{value}</span>;
                break;
        }

        switch (props.column.data) {
            case "ItemNo":
                if (item.AdURL && item.AdURL.length > 0) {
                    result = <div className="item-no" onClick={onImageClick}><img className="item-image" loading="lazy" src={item.AdURL} /><div>{result}</div></div>;
                } else {
                    result = <div className="item-no"><div>{result}</div></div>;
                }
                break;
            case "Description":
                if (item.IsNewItem) {
                    result = <Space direction="vertical" size="small" style={{ width: '100%' }}><Badge.Ribbon text="new" color="green">{result}</Badge.Ribbon></Space>;
                        //<Badge count="new" style={{ backgroundColor: '#52c41a', fontSize: 11 }} offset={[20, 3]} size="small">{result}</Badge>;
                }
                break;
            default:
                break;

        }
        return result;
    }


    return <div className={props.css} title={props.column.title}>{getValue()}</div>;
};




