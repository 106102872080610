import React, { useState, useRef } from 'react';
import * as log from 'loglevel';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    Message,
    MessageTypes,
    MessengerSession,
    UserRole,
    VideoStatus
} from 'model/index';
import _ from "lodash";
import { Button, Layout, Modal } from 'antd';
import {
    InfoCircleTwoTone,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import {
    MinusSquareOutlined,
    PlusSquareOutlined,
    SyncOutlined
} from '@ant-design/icons';
import axios from 'axios';
import clsx from 'clsx';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import NoMessages from './NoMessages';
import OrderButton from './OrderButton';

import {
    sendMessage,
    selectMessages,
    selectActiveSession,
    selectWindowVisibleStatus,
    selectWindowExpandadStatus,
    updateWindowExpandedStatus,
    getMessagesAsync,
    selectRefreshMessages,
    disableVideo,
    enableVideo,
    selectVideoStatus,
    selectActiveVideoSessionIds,
    selectSidebarCollapsed,
    setSidebarCollapsed
} from 'store/messenger';
import { selectUser } from 'store/user';
import MessageList from './MessageList';
import { SessionList } from './SessionList';
import { MeetingStatus } from './components/MeetingStatus';
import { Video } from './video/index';
import { Height } from '@material-ui/icons';

import useDebounce from 'components/hooks/useDebouncedEffect';

import { Filters } from './Filters';

const {
    Header,
    Content,
    Sider,
    Footer } = Layout;

interface State {
    inputActive: boolean,
    uploadProgress: number,
    videoVisible: boolean,
    completedMeetings: Number[]
}

export const MessengerWindow: React.FC = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectUser);

    const activeSession: MessengerSession = useAppSelector(selectActiveSession);

    const currentSessionMessages = useAppSelector(selectMessages);

    const windowVisibleStatus = useAppSelector(selectWindowVisibleStatus);

    const windowExpandedStatus = useAppSelector(selectWindowExpandadStatus);

    const refreshMessages = useAppSelector(selectRefreshMessages);

    const videoStatus: VideoStatus = useAppSelector(selectVideoStatus);

    const activeVideoSessionIds: number[] = useAppSelector(selectActiveVideoSessionIds);

    const fileInput = useRef(null);

    const contentInput = useRef(null);

    const sidebarCollapsed: boolean = useAppSelector(selectSidebarCollapsed);

    const [state, setState] = useState<State>({
        inputActive: false,
        uploadProgress: 0,
        videoVisible: false,
        completedMeetings: []
    });

    let messagesEnd: HTMLDivElement | null = null;

    React.useEffect(() => {
        if (videoStatus == VideoStatus.Disconnected) {
            setState(prevState => {
                return {
                    ...prevState,
                    videoVisible: false,
                }
            });
            window.dispatchEvent(new Event('disconnectFromRoom'));

            dispatch(setSidebarCollapsed(false));
        }
    }, [videoStatus]);

    React.useEffect(() => {
        if (contentInput.current) {
            if (state.inputActive && contentInput.current.innerText === "Reply...") {
                contentInput.current.innerText = "";
            }
            if (!state.inputActive && contentInput.current.innerText.length === 0) {
                contentInput.current.innerText = "Reply...";
            }
        }
    }, [state.inputActive]);

    React.useEffect(() => {
        scrollToMessageEnd();
    }, [currentSessionMessages]);

    React.useEffect(() => {
        //console.log("activeSession:", activeSession);
        //console.log("contentInput:", contentInput.current);
        if (contentInput.current) {
            contentInput.current.focus();
        }
    }, [activeSession]);

    React.useEffect(() => {

        if (windowVisibleStatus) {
            if (contentInput.current) {
                contentInput.current.focus();
            }
            //Refresh messages
            if (activeSession != null && !refreshMessages) {
                dispatch(getMessagesAsync(activeSession));
            }
        }
    }, [windowVisibleStatus]);

    React.useEffect(() => {
        if (refreshMessages) {
            dispatch(getMessagesAsync(activeSession));
        }
    }, [refreshMessages]);

    const expandWindow = () => {
        dispatch(updateWindowExpandedStatus(!windowExpandedStatus))
    }

    const sendSessionMessage = () => {
        if (contentInput.current.innerText.length > 0) {

            let message: Message = {
                messageType: MessageTypes.Text,
                body: contentInput.current.innerText,
                sessionId: activeSession.sessionId
            };

            dispatch(sendMessage(message));

            contentInput.current.innerText = "";

            contentInput.current.focus();

            scrollToMessageEnd();

            //console.log(incomingMessageSound);

            //audio.play();
        }
    }

    const uploadFile = () => {
        //console.log("UPLOAD FILE");
        fileInput.current.click();
    }

    const scrollToMessageEnd = () => {
        if (messagesEnd) {
            messagesEnd.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const onFilesSelected = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            //console.log(event.target.files);

            //const objectURL = window.URL.createObjectURL(event.target.files[0]);
            //console.log(objectURL);

            let file = event.target.files[0];
            //console.log(file);

            let formData = new FormData();
            formData.append('file', file);

            const documentData = {
                showId: globalThis.showId,
                fileType: "messenger"
            }
            formData.append('data', JSON.stringify(documentData));

            const fileName = file.name;
            createDocument(formData, (percentage: any) => {
                if (percentage === 100) {
                    percentage = 0;
                }
                setState(prevState => {
                    return {
                        ...prevState,
                        uploadProgress: percentage
                    }
                });

            }).then(function (result) {
                //console.log(fileName, result.data.url);

                let message: Message = {
                    messageType: MessageTypes.File,
                    url: result.data.url,
                    body: fileName,
                    sessionId: activeSession.sessionId
                };

                dispatch(sendMessage(message));

                scrollToMessageEnd();

            });

        }
    };

    const createDocument = (data: any, progressCallback: any) => {
        const config = {
            onUploadProgress: (progressEvent: any) => {
                const percentCompleted: number = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                //console.log(percentCompleted)
                progressCallback(percentCompleted);
            }
        }

        return axios.post("https://media.tradeshoweasy.com/api/document/upload", data, config);

        //return axios.post("/messenger/document", data, config);
    };

    const onShowVideo = (notify: boolean) => {
        if (state.videoVisible) {

            setState(prevState => {
                return {
                    ...prevState,
                    videoVisible: false
                }
            });

            dispatch(disableVideo());

            window.dispatchEvent(new Event('disconnectFromRoom'));

        } else {
            dispatch(enableVideo());

            setState(prevState => {
                return {
                    ...prevState,
                    videoVisible: true
                }
            });
            if (notify) {
                let message: Message = {
                    messageType: MessageTypes.Video,
                    body: `${user.company} started video call.`,
                    sessionId: activeSession.sessionId
                };
                dispatch(sendMessage(message));
            }
        }
    }

    const messengerClass = clsx({
        'messenger-window': true,
        'expanded': windowExpandedStatus
    });

    const footerClass = clsx({
        'active': state.inputActive,
        'hidden': activeSession == null || (state.videoVisible && windowExpandedStatus)
    });

    const headerClass = clsx({
        'hidden': activeSession == null,
        'video': state.videoVisible
    });

    const contentClass = clsx({
        'hidden': state.videoVisible && windowExpandedStatus,
        'messenger-message-list-messages': true
    });

    const cutoff: number = 25;

    //console.log("RENDER MESSENGER:");
    let messageHeader = "";
    if (activeSession) {
        switch (user.role) {
            case UserRole.Customer:
                messageHeader = activeSession.vendorName;
                break;
            case UserRole.Vendor:
                messageHeader = activeSession.customerName;
                break;
            case UserRole.Broker:
                messageHeader = activeSession.customerName;
                break;
            default:
                break;
        }
        if (!windowExpandedStatus) {
            messageHeader = messageHeader.length < cutoff ? messageHeader : messageHeader.substr(0, cutoff - 1) + '...';
        }
    }

    const displayVideoInsession: boolean = activeSession != null && !state.videoVisible && activeVideoSessionIds.includes(activeSession.sessionId);

    const windowTitle = windowExpandedStatus ? "Minimize" : "Full Screen";

    const windowIcon = windowExpandedStatus ? <React.Fragment>Minimize <MinusSquareOutlined /></React.Fragment> : <React.Fragment>Full Screen <PlusSquareOutlined /></React.Fragment>;

    const messengerTitle: string = "Meetings & Messages";

    const onShowEchoHelp = () => {

        return Modal.info({
            title: 'How To Eliminate Conference Call Echo & Feedback',
            maskClosable: true,
            icon: <InfoCircleTwoTone />,
            width: 800,
            content: (
                <React.Fragment>
                    <ul>
                        <li>Plug in headphones or headset if you can.</li>
                        <li>If you can't use headphone or headset, attendees should mute themselves when they're not speaking.</li>
                    </ul>
                </React.Fragment>
            ),
            onOk: () => {
            }
        });
    }

    const toggleCollapsed = () => {
        dispatch(setSidebarCollapsed(!sidebarCollapsed));
    }

    if (activeSession == null) {
        return <NoMessages />;
    } else {
        return (
            <div className={messengerClass}>
                <div className="messenger-header"
                    onDoubleClick={expandWindow}>
                    <div className="messenger-header-title">
                        {messengerTitle}
                    </div>
                    <div className="messenger-header-expand"
                        onClick={expandWindow}
                        title={windowTitle}>
                        {windowIcon}
                    </div>
                </div>
                <Layout>
                    <Sider width={sidebarCollapsed ? 0 : 250} theme="light" style={{ overflowY: 'scroll' }}>
                        <div style={{
                            position: 'fixed',
                            zIndex: 998,
                            width: sidebarCollapsed ? 0 : 234,
                            height: sidebarCollapsed ? 0 : 65,
                            backgroundColor: '#FFFFFF',
                            padding: sidebarCollapsed ? 0 : 5,
                            borderBottom: '1px solid #d9d9d9'
                        }}>
                            {!sidebarCollapsed &&
                                <Filters />
                            }
                        </div>
                        <div style={{ marginTop: 65 }}>
                            {!sidebarCollapsed &&
                                <SessionList />
                            }
                        </div>
                    </Sider>
                    <Layout className="messenger-message-list">

                        <Header className="info">
                            <div className="session-title">
                                <div className="company-name">
                                    {sidebarCollapsed &&
                                        <MenuUnfoldOutlined onClick={toggleCollapsed} style={{ fontSize: 16, color: '#1890ff' }} title="Show Sidebar" />
                                    }
                                    <span
                                        onClick={() => onShowVideo(true)}>
                                        {state.videoVisible ? <Button type="link" title="End video call." icon={<VideocamOff />} /> : <Button type="link" title="Video call. Click to start a video call." icon={<Videocam />} />}
                                    </span>
                                    <span>
                                        {messageHeader}
                                    </span>
                                    <span style={{ marginLeft: "auto" }}>
                                        <OrderButton />
                                    </span>
                                </div>

                                {(state.videoVisible && activeSession != null) &&
                                    <div className="meeting-status">
                                        <MeetingStatus />
                                        <div className="echo-help pointer" onClick={onShowEchoHelp}>
                                            <InfoCircleTwoTone /> Video Help
                                        </div>
                                    </div>
                                }
                            </div>
                            {displayVideoInsession && (
                                <div className="session-notifcation">
                                    <Button type="link"
                                        onClick={() => onShowVideo(true)}
                                        icon={<SyncOutlined spin />}>
                                        Video call in progress, click to join
                                    </Button>
                                </div>
                            )}
                        </Header>
                        {state.videoVisible && (
                            <React.Fragment>
                                <Content className={headerClass}>
                                    <Video />
                                </Content>
                            </React.Fragment>
                        )}
                        <Content className={contentClass}>
                            <MessageList
                                windowExpanded={windowExpandedStatus}
                                scrollToMessageEnd={scrollToMessageEnd}
                                onShowVideo={onShowVideo}
                            />
                            <div ref={(element: HTMLDivElement) => {
                                if (element) {
                                    messagesEnd = element;
                                    //console.log(element);
                                    scrollToMessageEnd();
                                }
                            }}> &nbsp; </div>
                        </Content>
                        <Footer className={footerClass}>
                            <div className="upload-progress" style={{
                                width: state.uploadProgress + "%"
                            }}></div>
                            <div role="button"
                                tabIndex={0}
                                contentEditable={true}
                                placeholder="Write a reply..."
                                className="user-input-text"
                                onFocus={(event: React.FocusEvent) => {
                                    setState(prevState => {
                                        return {
                                            ...prevState,
                                            inputActive: true
                                        }
                                    });
                                }}
                                onBlur={(event: React.FocusEvent) => {
                                    setState(prevState => {
                                        return {
                                            ...prevState,
                                            inputActive: false
                                        }
                                    });
                                }}
                                ref={contentInput}
                                onKeyPress={(event: React.KeyboardEvent) => {
                                    if (event.charCode == 13) {
                                        sendSessionMessage();
                                        event.preventDefault();
                                    }
                                }}
                            >
                            </div>
                            <div className="user-buttons">
                                <img title="Send Message"
                                    className="icon"
                                    src="https://tradeshoweasy.s3.us-east-2.amazonaws.com/asset/send-icon.svg"
                                    onClick={sendSessionMessage} />
                                <img title="Upload File"
                                    className="icon"
                                    src="https://tradeshoweasy.s3.us-east-2.amazonaws.com/asset/file-icon.svg"
                                    onClick={uploadFile} />
                                <input
                                    type="file"
                                    name="files[]"
                                    ref={fileInput}
                                    onChange={onFilesSelected}
                                />
                            </div>
                        </Footer>
                    </Layout>
                </Layout>
            </div>
        );
    }
}
