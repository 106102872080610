import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { OrderFilterType, OrderFilter, OrderOption, User, UserRole, Customer } from 'model/index';
import {
    Select,
    Button,
    Input,
    Menu,
    Dropdown
} from 'antd';
const { Search } = Input;

import {
    Vendor,
} from 'model/index';
import {
    filterType,
    selectFilterType,
    selectCustomer,
    selectOrderOptions,
    selectVendors,
    filterByQuery,
    resetAllFilters,
    selectSearchQuery,
    selectLocations,
    selectShow
} from 'store/order';
import { DefaultGuid } from 'utility/utility';
import { selectUser } from 'store/user';
import {
    SnippetsTwoTone
} from '@ant-design/icons';

import type { MenuProps } from 'antd';

import {
    useParams
} from "react-router-dom";

import useDebounce from 'components/hooks/useDebouncedEffect';

interface State {
    searchText: string;
    isFiltered: boolean;
}

export const FilterOptionsComponent: React.FC = () => {

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        setTimeout(() => {
            checkCategory();
        }, 500);
    }, []);

    const params = new window.URLSearchParams(window.location.search);

    const searchQuery: string = useAppSelector(selectSearchQuery);

    const [state, setState] = useState<State>({
        searchText: searchQuery,
        isFiltered: false
    });

    const show = useAppSelector(selectShow);

    const customer = useAppSelector(selectCustomer);

    const locations: Customer[] = useAppSelector(selectLocations);

    const currentFilterType: OrderFilter = useAppSelector(selectFilterType);

    const orderOptions: OrderOption = useAppSelector(selectOrderOptions);

    const vendors = useAppSelector(selectVendors);

    const user: User = useAppSelector(selectUser);

    const { Option, OptGroup } = Select;

    const handleChange = (value: any) => {
        if (value.indexOf("category:") > -1) {
            let filter: OrderFilter = {
                filterType: OrderFilterType.Category,
                query: value.toString().replace("category:", "")
            };
            dispatch(filterType(filter));
        } else if (value.indexOf("tag:") > -1) {
            let filter: OrderFilter = {
                filterType: OrderFilterType.Tag,
                query: value.toString().replace("tag:", "")
            };
            dispatch(filterType(filter));
        } else {
            let filter: OrderFilter = {
                filterType: value,
                query: ""
            };
            dispatch(filterType(filter));
        }
    };

    const resetOnClick = () => {
        setState(prevState => {
            return {
                ...prevState,
                searchText: ""
            }
        });
        dispatch(resetAllFilters());
    };

    const getOptions = () => {


        const children = [];
        let key: number = 1;
        children.push(<Option key={key++} value={OrderFilterType.All}>All Items</Option>);

        if (orderOptions.primeDealsEnabled && orderOptions.primeDealsTitle && orderOptions.primeDealsTitle.length > 0) {
            children.push(<Option key={OrderFilterType.Prime} value={OrderFilterType.Prime}>{orderOptions.primeDealsTitle}</Option>);
        }

        if (user.role == UserRole.Customer && orderOptions.virtualBoothEnabled && orderOptions.virtualBoothTitle && orderOptions.virtualBoothTitle.length > 0) {
            children.push(<Option key={OrderFilterType.Booth} value={OrderFilterType.Booth}>{orderOptions.virtualBoothTitle} Booths</Option>);
        }

        let tagSet = new Set();

        //if (orderOptions.tags && orderOptions.tags.length > 0) {
        //    orderOptions.tags.forEach(tag => {
        //        let key: string = `tag:${tag}`;
        //        children.push(<Option key={key} value={key}>{tag}</Option>);
        //    });
        //}

        const showOrderRelatedMenus = customer && customer.customerId != DefaultGuid;

        let hasNewItems: boolean = false;
        let hasContracts: boolean = false;
        let hasVideos: boolean = false;
        let hasDocuments: boolean = false;

        let categoriesSet = new Set();

        vendors.map((vendor: Vendor) => {
            if (vendor.newItems) {
                hasNewItems = true;
            }
            if (vendor.contracts) {
                hasContracts = true;
            }
            if (vendor.videos) {
                hasVideos = true;
            }
            if (vendor.documents) {
                hasDocuments = true;
            }
            if (vendor.category != null && vendor.category.length > 0) {
                try {
                    let multipleCategories = vendor.category.split(",");
                    multipleCategories.forEach(category => {
                        categoriesSet.add(category);
                    });
                } catch (e) {

                }
            }
            if (vendor.tags && vendor.tags.length > 0) {
                vendor.tags.forEach(tag => {
                    tagSet.add(tag);
                });
            }
        });

        let tags = Array.from(tagSet).sort();
        if (tags.length > 0) {
            tags.map((tag: string, index: number) => {
                children.push(<Option key={`tag:${index}`} value={`tag:${tag}`}>{tag}</Option>);
            });
        }

        if (hasContracts && orderOptions.contracts) {
            children.push(<Option key={OrderFilterType.Contracts} value={OrderFilterType.Contracts}>Contracts</Option>);
        }

        if (hasNewItems) {
            children.push(<Option key={OrderFilterType.New} value={OrderFilterType.New}>New Items</Option>);
        }

        if (showOrderRelatedMenus) {
            children.push(<Option key={OrderFilterType.Purchased} value={OrderFilterType.Purchased}>Purchased</Option>);
        }

        if (hasVideos) {
            children.push(<Option key={OrderFilterType.Videos} value={OrderFilterType.Videos}>Videos</Option>);
        }

        if (hasDocuments) {
            children.push(<Option key={OrderFilterType.Documents} value={OrderFilterType.Documents}>Resource & POS Documents</Option>);
        }

        if (orderOptions.history && user.role == UserRole.Customer) {
            children.push(<Option key={OrderFilterType.History} value={OrderFilterType.History}>History Items</Option>);
        }

        let categories = Array.from(categoriesSet).sort();
        if (categories.length > 0) {
            let categoryGroup = <OptGroup key="category" label="Categories">
                {categories.map((category: string, index: number) => {
                    return <Option key={`category:${index}`} value={`category:${category}`}>{category}</Option>;
                })
                }
            </OptGroup>;
            children.push(categoryGroup);
        }
        return children;
    };

    const checkCategory = () => {
        const categoryFromQueryString = params.get('category');
        if (categoryFromQueryString) {
            let filter: OrderFilter = {
                filterType: OrderFilterType.Category,
                query: categoryFromQueryString
            };
            dispatch(filterType(filter));
        }
    };

    const getValue = () => {
        switch (currentFilterType.filterType) {
            case OrderFilterType.Category:
                return `category:${currentFilterType.query}`;
             case OrderFilterType.Tag:
                return `tag:${currentFilterType.query}`;
            default:
                return currentFilterType.filterType;
        }
    };

    const searhQueryOnChange = (searchText: string) => {
        setState(prevState => {
            return {
                ...prevState,
                searchText: searchText
            }
        });
    };

    useDebounce(() => {
        dispatch(filterByQuery(state.searchText));
    }, 200, [state]);






    const isCustomer: boolean = user.role == UserRole.Customer;

    const customerExportMenu = () => {

        const printOrderSummaryUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/view-summary/${customer.customerId}`;
        const exportOrderUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/export/${customer.customerId}`;

        const prinOrderUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/print-details/${customer.customerId}`;

        const printDetailsByLocationUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/print-by-location/${customer.customerId}`;

        const printShowBookUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/show-book/${customer.customerId}?export=false`;
        const exportShowBookUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/show-book/${customer.customerId}?export=true`;

        const printHistoryUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/print-history/${customer.customerId}?export=false`;
        const exportHistoryUrl: string = `https://www.tradeshoweasy.com/report/${show.showId}/export-history/${customer.customerId}?export=true`;

        let index: number = 1;
        let items: MenuProps['items'] = [
            {
                key: index++,
                label: (<a target="_blank" rel="noopener noreferrer" href={printOrderSummaryUrl}>Print Order Summary</a>)
            },
            {
                key: index++,
                label: (<a target="_blank" rel="noopener noreferrer" href={prinOrderUrl}>Print Order</a>)
            }
        ];
        if (locations.length > 0) {
            items.push(
                {
                    key: index++,
                    label: (<a target="_blank" rel="noopener noreferrer" href={printDetailsByLocationUrl}>Print Order By Location</a>)
                }
            );
        }
        items.push(
            {
                key: index++,
                label: (<a target="_blank" rel="noopener noreferrer" href={exportOrderUrl}>Export Order</a>)
            }
        );
        if (orderOptions.showBookTitle && orderOptions.showBookTitle.length > 0) {
            items.push(
                {
                    key: index++,
                    label: (<a target="_blank" rel="noopener noreferrer" href={printShowBookUrl}>Print Show Book</a>)
                }
            );
            items.push(
                {
                    key: index++,
                    label: (<a target="_blank" rel="noopener noreferrer" href={exportShowBookUrl}>Export Show Book</a>)
                }
            );
        }
        if (orderOptions.history) {
            items.push(
                {
                    key: index++,
                    label: (<a target="_blank" rel="noopener noreferrer" href={printHistoryUrl}>Print History</a>)
                }
            );
            items.push(
                {
                    key: index++,
                    label: (<a target="_blank" rel="noopener noreferrer" href={exportHistoryUrl}>Export History</a>)
                }
            );
        }

        return (
            <Dropdown menu={{ items }}>
                <Button type="link" icon={<SnippetsTwoTone />}>Print & Export</Button>
            </Dropdown>);

    }

    return (
        <React.Fragment>
            <Input.Group compact style={{}}>
                <Select style={{ width: 170 }}
                    size="middle"
                    filterOption={(input, option) =>
                        option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    optionFilterProp="children"
                    placeholder="Filter Items"
                    defaultValue={getValue()}
                    onChange={handleChange}
                    value={getValue()}
                    listHeight={500}>
                    {getOptions()}
                </Select>
                <Search autoFocus={true}
                    style={{ width: 170 }}
                    placeholder="Search Items..."
                    onSearch={value => searhQueryOnChange(value)}
                    onChange={e => searhQueryOnChange(e.target.value)}
                    allowClear
                    size="middle"
                    value={state.searchText}
                />
                <Button type="link" onClick={resetOnClick}>
                    Reset
                </Button>
                {isCustomer && customerExportMenu()}
            </Input.Group>
        </React.Fragment>
    );
}

export const FilterOptions = React.memo(FilterOptionsComponent);
